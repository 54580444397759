@import-normalize;

html,
body,
#app_root {
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100vw;
}
